import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro" //eslint-disable-line
import { css } from "styled-components/macro" //eslint-disable-line
import BackIcon from "react-feather/dist/icons/chevron-left"

import { StaticLayout } from "../components/StaticLayout"

const ContactSuccessPage = () => (
  <StaticLayout headingText="Message Sent" tw="min-h-72">
    <h2>Thank you!</h2>
    <p>Your message was sent. We'll get back to you as soon as we can.</p>
    <div tw="mt-4">
      <Link to="/" tw="text-primary-600 hocus:text-primary-700">
        <BackIcon tw="inline-block" size="18" /> Back to Home
      </Link>
    </div>
  </StaticLayout>
)

export default ContactSuccessPage
